export const yaxis = [
  {
    show: true,
    opposite: false,
    reversed: false,
    max: undefined,
    min: undefined,
    floating: false,
    tickAmount: undefined,
    labels: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      align: undefined,
      rotate: 0,
      style: {
        colors: undefined,
        fontSize: 11,
        fontWeight: 400,
      },
    },
    axisBorder: {
      show: false,
      color: "#e0e0e0",
    },
    axisTicks: {
      show: false,
      color: "#e0e0e0",
    },
    title: {
      text: undefined,
      offsetY: 0,
      offsetX: 0,
      style: {
        color: undefined,
        fontSize: 11,
        fontWeight: 700,
      },
    },
  },
];

export const xaxis = {
  type: "category",
  offsetX: 0,
  offsetY: 0,
  position: "bottom",
  labels: {
    show: true,
    rotate: -45,
    rotateAlways: false,
    trim: true,
    offsetX: 0,
    offsetY: 0,
    style: {
      colors: undefined,
      fontSize: 12,
      fontWeight: 400,
    },
  },
  axisBorder: {
    show: true,
    color: "#e0e0e0",
  },
  axisTicks: {
    show: true,
    color: "#e0e0e0",
  },
  title: {
    text: undefined,
    style: {
      color: undefined,
      fontSize: 12,
      fontWeight: 700,
    },
  },
  crosshairs: {
    show: true,
    width: 1,
    position: "back",
    opacity: 0.9,
    stroke: {
      color: "#b6b6b6",
      width: 1,
    },
    fill: {
      type: "solid", // solid, gradient
      color: "#B1B9C4",
      gradient: {
        colorFrom: "#D8E3F0",
        colorTo: "#BED1E6",
        opacityFrom: 0.4,
        opacityTo: 0.5,
      },
    },
    dropShadow: {
      enabled: false,
      left: 0,
      top: 0,
      blur: 1,
      opacity: 0.4,
    },
  },
};

export const grid = {
  show: true,
  borderColor: "#e0e0e0",
  strokeDashArray: 0,
  position: "back",
  xaxis: {
    lines: {
      show: false,
    },
  },
  yaxis: {
    lines: {
      show: true,
    },
  },
  row: {
    colors: undefined, // takes as array which will be repeated on rows
    opacity: 0.5,
  },
  column: {
    colors: undefined, // takes an array which will be repeated on columns
    opacity: 0.5,
  },
  padding: {
    top: 0,
    right: 25,
    bottom: 0,
    left: 15,
  },
};

export const pointAnnotation = {
  x: null,
  y: null,
  yAxisIndex: 0,
  seriesIndex: 0,
  marker: {
    size: 4,
    fillColor: "#fff",
    strokeWidth: 2,
    strokeColor: "#333",
    shape: "circle",
    offsetX: 0,
    offsetY: 0,
    radius: 2,
    cssClass: "",
  },
  label: {
    borderColor: "#c2c2c2",
    borderWidth: 1,
    text: undefined,
    textAnchor: "middle",
    offsetX: 0,
    offsetY: -15,
    style: {
      background: "#fff",
      color: undefined,
      fontSize: 11,
      fontWeight: 400,
      cssClass: "",
      padding: {
        left: 5,
        right: 5,
        top: 2,
        bottom: 2,
      },
    },
  },
  image: {
    path: undefined,
    width: 20,
    height: 20,
    offsetX: 0,
    offsetY: 0,
  },
};

export const xAxisAnnotation = {
  x: 0,
  x2: undefined,
  strokeDashArray: 1,
  fillColor: "#c2c2c2",
  borderColor: "#c2c2c2",
  borderWidth: 1,
  opacity: 0.3,
  offsetX: 0,
  offsetY: 0,
  label: {
    borderColor: "#c2c2c2",
    borderWidth: 1,
    text: undefined,
    textAnchor: "middle",
    orientation: "vertical",
    position: "top",
    offsetX: 0,
    offsetY: 0,
    style: {
      background: "#fff",
      color: undefined,
      fontSize: 11,
      fontWeight: 400,
      cssClass: "",
      padding: {
        left: 5,
        right: 5,
        top: 2,
        bottom: 2,
      },
    },
  },
};

export const yAxisAnnotation = {
  y: 0,
  y2: undefined,
  strokeDashArray: 1,
  fillColor: "#c2c2c2",
  borderColor: "#c2c2c2",
  borderWidth: 1,
  opacity: 0.3,
  offsetX: 0,
  offsetY: 0,
  yAxisIndex: 0,
  label: {
    borderColor: "#c2c2c2",
    borderWidth: 1,
    text: undefined,
    textAnchor: "end",
    position: "right",
    offsetX: 0,
    offsetY: -3,
    style: {
      background: "#fff",
      color: undefined,
      fontSize: 11,
      fontWeight: 400,
      cssClass: "",
      padding: {
        left: 5,
        right: 5,
        top: 2,
        bottom: 2,
      },
    },
  },
};

export const chartDefaults = {
  apex: function() {
    return {
      series: [],
      annotations: {
        position: "front",
        yaxis: [],
        xaxis: [],
        points: [],
      },
      chart: {
        type: "bar",
        background: "",
        foreColor: "#333",
        offsetX: 0,
        offsetY: 0,
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
        dropShadow: {
          enabled: false,
          enabledOnSeries: undefined,
          top: 2,
          left: 2,
          blur: 4,
          color: "#000",
          opacity: 0.35,
        },
        fontFamily: "Roboto",
        height: 450,
        width: 700,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%", // should be in percent 0 - 100
          barHeight: "70%", // should be in percent 0 - 100
          distributed: false,
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          colors: {
            ranges: [],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
          },
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
        heatmap: {
          radius: 2,
          enableShades: true,
          shadeIntensity: 0.5,
        },
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          hollow: {
            margin: 5,
            size: "50%",
            background: "#fff",
            position: "front",
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              color: "#000",
              opacity: 0.5,
            },
          },
          track: {
            show: true,
            startAngle: undefined,
            endAngle: undefined,
            background: "#f2f2f2",
            strokeWidth: "97%",
            opacity: 1,
            margin: 5,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              color: "#000",
              opacity: 0.5,
            },
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: 16,
              color: undefined,
              offsetY: 0,
            },
            value: {
              show: true,
              fontSize: 14,
              color: undefined,
              offsetY: 16,
            },
            total: {
              show: false,
              label: "Total",
              fontSize: 16,
              color: undefined,
            },
          },
        },
        pie: {
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          dataLabels: {
            offset: 0, // offset by which labels will move outside
          },
          donut: {
            size: "65%",
            background: undefined,
            labels: {
              // These are the inner labels appearing inside donut
              show: false,
              name: {
                show: true,
                fontSize: 16,
                color: undefined,
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: 20,
                color: undefined,
                offsetY: 10,
              },
              total: {
                show: false,
                showAlways: false,
                label: "Total",
                fontSize: 16,
                color: undefined,
              },
            },
          },
        },
        radar: {
          offsetX: 0,
          offsetY: 0,
          polygons: {
            // strokeColor: '#e8e8e8', // should be deprecated in the minor version i.e 3.2
            strokeColors: "#e8e8e8",
            connectorColors: "#e8e8e8",
            fill: {
              colors: undefined,
            },
          },
        },
      },
      colors: undefined,
      theme: {
        mode: "light",
      },
      dataLabels: {
        enabled: true,
        textAnchor: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: 12,
          fontWeight: 700,
          colors: undefined,
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          borderRadius: 2,
          padding: 4,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: "#fff",
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      markers: {
        size: 0,
        colors: undefined,
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        hover: {
          size: undefined,
        },
      },
      xaxis: xaxis,
      yaxis: yaxis,
      grid: grid,
      stroke: {
        show: true,
        curve: "smooth", // "smooth" / "straight" / "stepline"
        lineCap: "butt", // round, butt , square
        colors: undefined,
        width: 2,
        dashArray: 0, // single value or array of values
      },
      fill: {
        type: "solid",
        colors: undefined,
        opacity: 0.85,
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
        pattern: {
          style: "squares", // String | Array of Strings
          width: 6,
          height: 6,
          strokeWidth: 2,
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        floating: false,
        position: "bottom", //top, bottom, left, right
        horizontalAlign: "center", // when position top/bottom -> left, right or center
        fontSize: 14,
        fontWeight: 400,
        width: undefined,
        height: undefined,
        offsetX: -20,
        offsetY: 0,
        labels: {
          colors: undefined,
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          fillColors: undefined,
          strokeColor: "#fff",
          radius: 12,
        },
        itemMargin: {
          horizontal: 5,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
    };
  },
};
