<template>
  <div class="additional-elements">
    <div
      :style="{ zIndex: index + 10 }"
      class="item-element"
      v-for="(element, index) in els"
      :key="element.id"
    >
      <draggable-moveable
        v-if="element.elType !== 'chart'"
        :id="element.id"
        :resizable="element.elType === 'section'"
        :keepRatio="element.elType !== 'div' || element.keepRatio === true"
      >
        <div class="slot" slot="body">
          <div
            :class="className"
            :style="{ zIndex: index + 10 }"
            v-html="element.html"
            :ref="'element-' + index"
          ></div>
        </div>
      </draggable-moveable>

      <!-- moveable doesn't work for charts very well, hence using vue-drag-resize -->
      <!-- NOTE: The props for vue-drag-resize won't work here ;) -->
      <draggable-resizable
        v-else
        :id="element.id"
        :x="element.position.x"
        :y="element.position.y"
        :w="element.src.chart.width"
        :h="element.src.chart.height"
        :z="10 + index"
        @onResize="resizeChart"
        @onDragStop="updateChartPositionInStore"
      >
        <div slot="body">
          <div class="graphmaker-wrap">
            <div :class="element.id" :ref="element.id" />
          </div>
        </div>
      </draggable-resizable>
    </div>
  </div>
</template>

<script>
import { chartDefaults, yaxis } from "../chart-defaults";
import { extend, extendArray } from "@/utils";

import draggableMoveable from "@/components/common/draggable-moveable/draggable-moveable.component.vue";
import draggableResizable from "@/components/common/draggable-resizable/draggable-resizable.component.vue";

import { mapActions, mapGetters } from "vuex";
import { ELEMENTS_MIXIN } from "@/components/common/mixins/elements.mixins";

export default {
  components: {
    "draggable-moveable": draggableMoveable,
    "draggable-resizable": draggableResizable,
  },
  mixins: [ELEMENTS_MIXIN],
  props: {
    initialElements: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data: function() {
    return {
      className: "accessory-element",
      els: this.initialElements,
      charts: [],
    };
  },
  computed: {
    ...mapGetters("elementsModule", [
      "canvasElements",
      "activeMoveable",
      "lastAddedChartId",
    ]),
    ...mapActions("elementsModule", ["getInitialElements", "setChartPosition"]),
  },
  watch: {
    canvasElements: function(newProps) {
      this.els = newProps;
    },
    initialElements: function(newProps) {
      this.els = newProps;
      this.renderCanvasElements();
    },
    activeElementId: function(newProp) {
      if (this.activeElementType === "section") {
        this.attachContentEditableEvent();
      }
    },
    lastAddedChartId: function(newProp, oldProp) {
      if (newProp !== oldProp) {
        window.setTimeout(() => {
          this.renderCanvasElements();
        }, 100);
      }
    },
  },
  beforeDestroy() {
    this.destroyChartOptions();
  },
  methods: {
    async renderCanvasElements() {
      this.els.map((el) => {
        let htmlString = el.html;

        if (htmlString) {
          htmlString = el.html.split("&quot;").join("");
          const fontFamily = htmlString.match(/font-family:\s*([^;}]*)/);
          const fontUrl = htmlString.match(
            /data-font\-url=\s*['"]([^"']+)['"];?/
          );

          if (fontFamily && fontFamily.length > 0 && fontFamily[1]) {
            // load fonts for elements
            this.loadProjectFonts({
              fontFamily: fontFamily[1].replace(/['"]+/g, ""),
              fontUrl:
                fontUrl && fontUrl.length && fontUrl[1] ? fontUrl[1] : null,
            });
          }
        }

        if (el.elType === "chart") {
          this.buildChartOptionsFromChartId(el);
        }
      });
    },

    resizeChart({ width, height }) {
      this.updateChartOptions({
        chart: {
          width: width,
          height: height,
        },
      });
    },
    updateChartPositionInStore(opts) {
      const position = {
        x: opts.left,
        y: opts.top,
      };
      this.$store.dispatch("elementsModule/setChartPosition", {
        id: this.activeChartId,
        position,
      });
    },
    buildChartOptionsFromChartId(el) {
      const chartSrc = el.src;

      if (chartSrc.yaxis) {
        chartSrc.yaxis = extendArray(chartSrc.yaxis, yaxis[0]);
      }
      const chartOpts = extend(chartDefaults.apex(), chartSrc);
      chartOpts.chart.id = el.id;

      if (window.Apex._chartInstances?.length) {
        const existingChart = window.ApexCharts.getChartByID(el.id);
        if (existingChart) {
          existingChart.destroy();
        }
      }

      if (this.$refs[el.id] && this.$refs[el.id][0]) {
        const chartObj = new window.ApexCharts(this.$refs[el.id][0], chartOpts);
        chartObj.render();

        // load fonts which are in chart
        this.loadProjectFonts({
          fontFamily: chartOpts.chart.fontFamily,
          fontUrl: chartOpts.chart.fontUrl,
        });

        this.charts.push(chartObj);
      } else {
        console.warn("chart element not found");
      }
    },
    destroyChartOptions: function() {
      this.charts.map((c) => {
        c.destroy();
      });
    },
    attachContentEditableEvent: function() {
      const me = this;
      for (let ref in this.$refs) {
        if (this.$refs[ref] && this.$refs[ref].length) {
          const section = this.$refs[ref][0].querySelector("section");
          if (section) {
            section.addEventListener("input", function() {
              me.activeMoveable.updateRect();
              me.saveElementInStore();
            });
          }
        }
      }
    },
    saveElementInStore() {
      this.$store.dispatch("elementsModule/updateElementById", {
        id: this.activeElementId,
        html: this.getActiveElementOuterHTML(this.activeElement),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./canvas-elements.component.scss";
</style>
