<template>
  <VueDragResize
    :preventActiveBehavior="true"
    :isActive="activeChartId === id"
    :x="x"
    :y="y"
    :w="w"
    :h="h"
    :z="z"
    :stickSize="14"
    :minh="10"
    :minw="10"
    @resizing="onResize"
    @dragstop="onDragStop"
    @activated="onActivated"
  >
    <slot name="body"></slot>
  </VueDragResize>
</template>

<script>
import VueDragResize from "vue-drag-resize";
Vue.component("vue-drag-resize", VueDragResize);

export default {
  name: "draggable-resizable",
  components: {
    VueDragResize,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    w: {
      type: Number,
    },
    h: {
      type: Number,
    },
    z: {
      type: Number,
    },
  },
  methods: {
    onResize({ left, top, width, height }) {
      this.$emit("onResize", {
        left,
        top,
        width,
        height,
        el: this.$refs[this.id],
      });
    },
    onDragStop(opts) {
      this.$emit("onDragStop", opts);
    },
    onActivated() {
      this.$emit("onActivated", { id: this.id });
    },
    onDeactivated() {
      this.$emit("onDeactivated");
    },
  },
};
</script>
