export const ELEMENTS_MIXIN = {
  methods: {
    getActiveElementOuterHTML(el) {
      return el && el.outerHTML.replace(/\bdata-v\S+/gi, "");
    },
    getActiveElementOuterHTMLById(id) {
      const el = document.getElementById(id);
      let HTMLstring = "";
      if (el) {
        HTMLstring = el.outerHTML;
      }

      return HTMLstring;
    },
  },
};
