<template>
  <div class="draggable-moveable">
    <slot name="body"></slot>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getTransformValues } from "../utils";
import { ELEMENTS_MIXIN } from "@/components/common/mixins/elements.mixins";

export default {
  name: "draggable",
  mixins: [ELEMENTS_MIXIN],
  props: {
    id: {
      required: true,
      type: String,
    },
    elType: {
      type: String,
    },
    w: {
      type: Number,
    },
    h: {
      type: Number,
    },
    z: {
      type: Number,
    },
    resizable: {
      type: Boolean,
      required: false,
      default: false,
    },
    keepRatio: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      moveable: null,
    };
  },
  computed: {
    ...mapGetters("elementsModule", ["activeElementEvent"]),
    ...mapActions("elementsModule", [
      "updateElementById",
      "changeMoveable",
      "changeActiveElement",
    ]),
  },
  watch: {
    activeElementId: function(newProp, oldProp) {
      if (newProp === this.id) {
        this.makeActive(newProp);
      } else {
        this.makeInactive(this.id);
      }
    },
  },
  mounted: function() {
    window.addEventListener("resize", this.updateControlBox.bind(this));
  },
  beforeDestroy: function() {
    if (this.moveable) {
      this.moveable.destroy();
    }
    window.removeEventListener("resize", this.updateControlBox.bind(this));
  },
  methods: {
    updateControlBox() {
      if (this.moveable) {
        this.moveable.updateRect();
      }
    },
    makeInactive(id) {
      let target = document.getElementById(id);

      if (target) {
        target.classList.remove("activated");

        // in case it's a textbox and it's editable, remove content-editable attr
        target.removeAttribute("contenteditable");
      }

      if (this.moveable) {
        this.moveable.target = null;

        this.$store.dispatch("elementsModule/changeMoveable", {
          activeMoveable: null,
        });
      }
    },
    makeActive(id) {
      let target = this.$refs[this.id];

      if (id) {
        target = document.getElementById(id);
      }

      if (!target.classList.contains("activated")) {
        target.classList.add("activated");
      }

      var prevTransforms = getTransformValues(target.style.transform);

      const transform = {
        translate: [prevTransforms.translate[0], prevTransforms.translate[1]],
        rotate: prevTransforms.rotate,
        scale: [prevTransforms.scale[0], prevTransforms.scale[1]],
      };

      const draw = (target) => {
        const { translate, scale, rotate } = transform;

        target.style.transform = `translate(${translate[0]}px, ${translate[1]}px) scale(${scale[0]}, ${scale[1]}) rotate(${rotate}deg)`;
      };

      this.moveable = new Moveable(
        document.querySelector(".main-content .graphproject-wrap"),
        {
          target: target,
          container: document.querySelector(".main-content .graphproject-wrap"),
          draggable: true,
          origin: false,
          scalable: this.keepRatio || !this.resizable,
          resizable: !this.keepRatio || this.resizable,
          rotatable: true,
          throttleRotate: 0,
          keepRatio: this.keepRatio && !this.resizable,
        }
      );

      this.$store.dispatch("elementsModule/changeMoveable", {
        activeMoveable: this.moveable,
      });

      this.moveable
        .on("scaleStart", ({ set, dragStart }) => {
          set(transform.scale);
          dragStart && dragStart.set(transform.translate);
        })
        .on("scale", ({ target, drag, scale }) => {
          transform.scale = scale;
          transform.translate = drag.beforeTranslate;
          draw(target);
        })
        .on("scaleEnd", () => {
          this.saveElement(target);
        })
        .on("resizeStart", ({ setOrigin, dragStart }) => {
          setOrigin(["%", "%"]);
          dragStart && dragStart.set(transform.translate);
        })
        .on("resize", ({ target, width, height, drag }) => {
          const beforeTranslate = drag.beforeTranslate;

          transform.translate = beforeTranslate;
          target.style.width = `${width}px`;
          target.style.height = `${height}px`;

          target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
          draw(target);
        })
        .on("resizeEnd", () => {
          this.saveElement(target);
        })
        .on("dragStart", (e) => {
          e.set(transform.translate);
        })
        .on("drag", (e) => {
          e.target.removeAttribute("contenteditable");

          transform.translate = e.beforeTranslate;
          draw(e.target);
        })
        .on("dragEnd", (e) => {
          this.saveElement(target);
        })
        .on("rotateStart", ({ set }) => {
          set(transform.rotate);
        })
        .on("rotate", ({ beforeRotate }) => {
          transform.rotate = beforeRotate;
          draw(target);
        })
        .on("rotateEnd", () => {
          this.saveElement(target);
        });
    },
    saveElement(target) {
      this.saveHTMLElementInStore(this.getActiveElementOuterHTML(target));
    },
    saveHTMLElementInStore(HTMLstring) {
      this.$store
        .dispatch("elementsModule/updateElementById", {
          id: this.id,
          html: HTMLstring,
        })
        .then(() => {
          // a dirty hack to de-select and re-select the element to make moveable work again after re-render
          // ONLY REQUIRED IF REACTIVITY IS ENABLED
          // i.e., when vuex state update causes re-render, then the below code is required
          // ..
          // ..
          // const activeElementId = this.activeElementId;
          // if (this.activeElementId) {
          //   this.$store
          //     .dispatch("elementsModule/changeActiveElement", { id: null })
          //     .then((response) => {
          //       this.$store.dispatch("elementsModule/changeActiveElement", {
          //         id: activeElementId,
          //       });
          //     });
          // }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
