export const parseStyleString = (str) => {
  return Array.from(str.matchAll(/(\w+)\((.+?)\)/gm)).reduce(
    (agg, [, fn, val]) => ({
      ...agg,
      [fn]: val,
    }),
    {}
  );
};

export const getTransformValues = (parsedTransformStr) => {
  const transformed = parseStyleString(parsedTransformStr);
  if (transformed.translate) {
    const translate = transformed.translate.split(",");
    const scale = transformed.scale ? transformed.scale.split(",") : [1, 1];
    return {
      translate: translate.map((tr) => {
        return parseFloat(tr);
      }),
      scale: scale.map((sc) => {
        return parseFloat(sc);
      }),
      rotate: parseFloat(transformed.rotate),
    };
  } else {
    return {
      translate: [0, 0],
      scale: [1, 1],
      rotate: 0,
    };
  }
};

export const getCSSFilterValues = (parsedFilterStr) => {
  // replace RGB with hex for drop-shadow before calling parseStyleString
  // otherwise, css filter returns rgb even though we specify hex
  // and that causes issues when splitting values
  const CSSfilter = parseStyleString(
    parsedFilterStr.replace(
      /\brgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/g,
      function($0, $1, $2, $3) {
        return (
          "#" +
          ("0" + Number($1).toString(16)).substr(-2) +
          ("0" + Number($2).toString(16)).substr(-2) +
          ("0" + Number($3).toString(16)).substr(-2)
        );
      }
    )
  );

  const brightness = CSSfilter.brightness ? CSSfilter.brightness : 1;
  const contrast = CSSfilter.contrast ? CSSfilter.contrast : 1;
  const blur = CSSfilter.blur ? CSSfilter.blur : 0;
  const saturate = CSSfilter.saturate ? CSSfilter.saturate : 1;

  const dropShadow = CSSfilter.shadow
    ? CSSfilter.shadow.split(" ")
    : ["transparent", 0, 0, 0];

  return {
    contrast: parseFloat(contrast),
    brightness: parseFloat(brightness),
    blur: parseFloat(blur),
    saturate: parseFloat(saturate),
    dropShadow: dropShadow.map((d, i) => {
      return i === 0 ? d : parseFloat(d);
    }),
  };
};
